import { OtxEvent } from './OtxEvent';
import { OtxFighter } from './OtxFighter';
import { OvertimeEntity } from './OvertimeEntity';
import { OvertimeEntityLocation } from './OvertimeEntityLocation';

export interface OtxFight extends OvertimeEntity, OvertimeEntityLocation {
	fight_type?: string;
	is_hidden: boolean;
	is_overtime: boolean;
	is_title_bout: boolean;
	title_bout_name: string;
	is_tournament_fight: boolean;
	name?: string;
	next_tournament_otx_fight_id?: string;
	number_of_rounds: number;
	starts_at_timezone: string;
	starts_at_utc_offset: number;
	starts_at: string;
	weight_class?: string;
	win_by?: string;
	youtube_url?: string;

	otx_event_id?: string;
	otx_fighter1_id?: string;
	otx_fighter2_id?: string;
	otx_winner_id?: string;

	otx_event: OtxEvent;
	otx_fighter1?: OtxFighter;
	otx_fighter2?: OtxFighter;
	otx_winner?: OtxFighter;
}

export enum FightTypes {
	MainEvent = 'Main Event',
	CoMainEvent = 'Co-Main Event',
	Featured = 'Featured',
}

export const IsWinner = (fight: OtxFight, fighter: OtxFighter) => {
	return fight?.otx_winner_id !== null && fight?.otx_winner_id == fighter?.id;
};

export const IsLoser = (fight: OtxFight, fighter: OtxFighter) => {
	return fight?.otx_winner_id !== null && fight?.otx_winner_id !== fighter?.id;
};

export const IsOvertime = (fight: OtxFight) => {
	return fight?.otx_winner_id !== null && fight?.is_overtime;
};

export const GetOpponent = (fight: OtxFight, fighter: OtxFighter) => {
	return fight?.otx_fighter1_id === fighter?.id ? fight?.otx_fighter2 : fight?.otx_fighter1;
};

export const GetTournamentRound = (tournamentFights: OtxFight[], fight: OtxFight) => {
	if (!tournamentFights || !fight) {
		return null;
	}

	const rounds = Object.values(TournamentRounds);
	let round = rounds.length;

	let current = fight;
	while (current?.next_tournament_otx_fight_id) {
		round--;
		current = tournamentFights.find((f) => f.id == current.next_tournament_otx_fight_id);
	}

	return rounds[round - 1];
};

export enum TournamentRounds {
	FirstRound = 'First Round',
	SemiFinals = 'Semi-Finals',
	Championship = 'Championship',
}
