import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Urls from '~/src/constants/urls';
import {
	imgPlaceholdersFighterFemaleBlueSideWebp,
	imgPlaceholdersFighterFemaleRedSideWebp,
	imgPlaceholdersFighterMaleBlueSideWebp,
	imgPlaceholdersFighterMaleRedSideWebp,
} from '~/src/images';
import { IsLoser, IsOvertime, IsWinner, OtxFight } from '~/src/models/OtxFight';
import { Genders, OtxFighter } from '~/src/models/OtxFighter';
import Picture from '~/src/overtime-lib/src/www/components/Picture';
import { RoutePaths } from '~/src/www/routes';
import './FightCard.scss';
import FontShrinker from './FontShrinker';

const getFighterImageUrl = (otx_fighter: OtxFighter, opponent: OtxFighter, color: string = 'red') => {
	if (!otx_fighter && !opponent) {
		return color == 'red' ? imgPlaceholdersFighterMaleRedSideWebp : imgPlaceholdersFighterMaleBlueSideWebp;
	}
	if (!otx_fighter && opponent.gender === Genders.Male) {
		return color == 'red' ? imgPlaceholdersFighterMaleRedSideWebp : imgPlaceholdersFighterMaleBlueSideWebp;
	}
	if (!otx_fighter && opponent.gender === Genders.Female) {
		return color == 'red' ? imgPlaceholdersFighterFemaleRedSideWebp : imgPlaceholdersFighterFemaleBlueSideWebp;
	}
	if (color == 'blue' && otx_fighter.hero_image_2_path) {
		return `${Urls.OvertimeImagesOtx}/${otx_fighter.hero_image_2_path}`;
	}
	if (otx_fighter.hero_image_path) {
		return `${Urls.OvertimeImagesOtx}/${otx_fighter.hero_image_path}`;
	}
	if (otx_fighter.image_path) {
		return `${Urls.OvertimeImagesOtx}/${otx_fighter.image_path}`;
	}
	if (color === 'red' && (otx_fighter.gender === Genders.Male || opponent.gender === Genders.Male)) {
		return imgPlaceholdersFighterMaleRedSideWebp;
	}
	if (color === 'red' && (otx_fighter.gender === Genders.Female || opponent.gender === Genders.Female)) {
		return imgPlaceholdersFighterFemaleRedSideWebp;
	}
	if (color === 'red') {
		return imgPlaceholdersFighterMaleRedSideWebp;
	}
	if (color === 'blue' && (otx_fighter.gender === Genders.Male || opponent.gender === Genders.Male)) {
		return imgPlaceholdersFighterMaleBlueSideWebp;
	}
	if (color === 'blue' && (otx_fighter.gender === Genders.Female || opponent.gender === Genders.Female)) {
		return imgPlaceholdersFighterFemaleBlueSideWebp;
	}
	if (color === 'blue') {
		return imgPlaceholdersFighterMaleBlueSideWebp;
	}

	return imgPlaceholdersFighterMaleBlueSideWebp;
};

const _FighterNameAndProfile = ({ fighter, side }: { fighter: OtxFighter; side: 'left' | 'right' }) => {
	return (
		<div className={`name${side == 'right' ? ' name--right' : ''}`}>
			{fighter ? (
				<>
					{fighter?.nick_name ? (
						<FontShrinker className="nick-name">"{fighter?.nick_name}"</FontShrinker>
					) : (
						<FontShrinker className="nick-name">&nbsp;</FontShrinker>
					)}

					{fighter?.first_name ? (
						<FontShrinker className="first-name">{fighter.first_name}</FontShrinker>
					) : (
						<FontShrinker className="first-name">&nbsp;</FontShrinker>
					)}

					{fighter?.last_name ? (
						<FontShrinker className="last-name">{fighter.last_name}</FontShrinker>
					) : (
						<FontShrinker className="last-name">&nbsp;</FontShrinker>
					)}

					{fighter?.is_active ? (
						<div className="name-link">
							<a href={`${RoutePaths.Fighters}/${fighter.id}`}>
								View Profile
								<FontAwesomeIcon icon={faArrowRight} />
							</a>
						</div>
					) : null}
				</>
			) : (
				<FontShrinker className="first-name">TBD</FontShrinker>
			)}
		</div>
	);
};

const _FighterNameAndProfileMobile = ({ fighter }: { fighter: OtxFighter }) => {
	return (
		<>
			{!fighter ? (
				<div className="fighter-name fighter-name--tbd">TBD</div>
			) : fighter.is_active ? (
				<a className="fighter-name" href={`${RoutePaths.Fighters}/${fighter.id}`}>
					{fighter.first_name?.at(0)}. {fighter.last_name}
				</a>
			) : (
				<div className="fighter-name">
					{fighter.first_name?.at(0)}. {fighter.last_name}
				</div>
			)}
		</>
	);
};

const _FighterPhoto = ({
	fighter,
	opponent,
	fight,
	side = 'left',
}: {
	fighter: OtxFighter;
	opponent: OtxFighter;
	fight: OtxFight;
	side: 'left' | 'right';
}) => {
	const mirrorBlueImage =
		side == 'right' &&
		(getFighterImageUrl(fighter, opponent, 'blue').includes(imgPlaceholdersFighterMaleBlueSideWebp) ||
			getFighterImageUrl(fighter, opponent, 'blue').includes(imgPlaceholdersFighterFemaleBlueSideWebp));

	return (
		<div className={`profile profile--${side}`}>
			<div
				className={`profile-image-container profile-image-container--${side}${mirrorBlueImage ? ' profile-image-container--mirror' : ''}`}
			>
				<Picture
					className={`profile-image profile-image--${side}  ${IsLoser(fight, fighter) ? 'profile-image--loser' : ''}`}
					src={getFighterImageUrl(fighter, opponent, side == 'left' ? 'red' : 'blue')}
					mobileWidth={285}
				/>
			</div>
			{IsWinner(fight, fighter) ? (
				<div className={`winner-tag winner-tag--${side}`}>
					<h3>Win</h3>
					{fight.win_by ? `by ${fight.win_by}` : ''}
				</div>
			) : (
				<div className="loser-tag"></div>
			)}
		</div>
	);
};

const FightCard = ({ fight }: { fight: OtxFight }) => {
	const fighter1 = fight.otx_fighter1;
	const fighter2 = fight.otx_fighter2;

	return (
		<section className="fight-card">
			<div className="fighters-container">
				<_FighterPhoto fighter={fighter1} opponent={fighter2} fight={fight} side="left" />
				<div className="info">
					<_FighterNameAndProfile fighter={fighter1} side="left" />
					<div className={`fight-info${fight.youtube_url || fight.is_tournament_fight ? ' fight-info--padding' : ''}`}>
						<div className="fight-lines">
							<div className="line"></div>
							{!fight.otx_winner && <h3 className="vs">vs</h3>}
							{fight.otx_winner && (
								<h3 className="final">
									Final
									{IsOvertime(fight) && ' - OT'}
								</h3>
							)}
							<div className="line"></div>
						</div>
						<div className="fight-weight-rounds">
							{fight.weight_class && <div className="weight-class">{fight.weight_class}</div>}
							{fight.number_of_rounds && <div className="number-of-rounds">{fight.number_of_rounds} rounds</div>}
						</div>
					</div>
					<_FighterNameAndProfile fighter={fighter2} side="right" />
				</div>
				<_FighterPhoto fighter={fighter2} opponent={fighter1} fight={fight} side="right" />
			</div>
			<div className="fighter-names-container-mobile">
				<_FighterNameAndProfileMobile fighter={fighter1} />
				<_FighterNameAndProfileMobile fighter={fighter2} />
			</div>
		</section>
	);
};

export default FightCard;
